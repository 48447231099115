<template>
  <div class="table-div">
    <div class="table-title">家庭成员信息</div>
    <div class="table-container">
      <Table
            border
            :columns="tableColumns"
            :data="familyData"
            :height="600"
          ></Table>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
        familyMemberId:{
            type:Object,
            default:()=>{
                return ''
            }
        }
  },
  data(){
    return{
      tableColumns:[
        {
          title: '与户主关系',
          key: 'relationship',
          // minWidth: 100,
          align: "center",
          render:(h,params)=>{
            return h('div',{},this.relationShip.map(item=>{
              if(item.dictKey == params.row.relationship){
                return item.dictValue
              }
            }))
          }
        },
        {
          title: '姓名',
          key: 'name',
          // minWidth: 100,
          align: "center",
        },
        {
          title: '性别',
          key: 'sex',
          // minWidth: 100,
          align: "center",
           render:(h,params)=>{
                        return h('div',{},params.row.sex == 0 ?'未知': params.row.sex == 1 ?'男':params.row.sex == 2?'女':'未知' )
           }
        },
        {
          title: '年龄',
          key: 'age',
          // minWidth: 100,
          align: "center",
        },
        {
          title: '是否家庭户代表',
          key: 'concact',
          // minWidth: 100,
          align: "center",
           render:(h,params)=>{
                        return h('div',{},params.row.contact == 1 ?'是':'否' )
           }
        },
        {
          title: '手机号',
          key: 'mobile',
          // minWidth: 100,
          align: "center",
        },
       
      ],
      familyData:[],
      relationShip:[]
    }
  },
  created(){
    this.getDicType('FAMILY_RELATIONSHIP','relationShip')
    this.getDetail()
  },
  methods:{
    getDetail(){
       if(this.userId && this.userId != ''){
         this.$get('/syaa/api/syuser/pc/residentArchive/selectUserOnlineHomeInfo',{
           userId:this.userId,
           familyMemberId:this.familyMemberId
         }).then(res=>{
          if(res.code == 200){
              this.familyData = res.data.members
          }else{
            this.$Message.error({
                    content:'家庭成员信息获取失败',
                    background:true
                  })
                  return
          }
        })
       }
      
    },
     // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
                console.log(this[model]);
            })
        },
   
  }
}
</script>

<style>

</style>